export function removeDatabaseMetaDeep(
  item: any,
  metaKeys: string[] = ['createdAt', 'updatedAt'],
): any {
  const newItem = getDeepClone(item)

  if (typeof newItem === 'object' && newItem !== null) {
    Object.keys(newItem).forEach((key) => {
      if (metaKeys.includes(key)) {
        delete newItem[key]
      } else {
        newItem[key] = removeDatabaseMetaDeep(newItem[key])
      }
    })
  }

  if (Array.isArray(newItem)) {
    return newItem.map((i) => removeDatabaseMetaDeep(i))
  }

  return newItem
}

export function removeIdFromObject<T extends { id: any }>(
  object: T,
): Omit<T, 'id'> {
  const output = getDeepClone(object)

  delete output.id

  return output
}

export function removeIdsFromArray<T extends { id: any }>(
  array: T[],
): Omit<T, 'id'>[] {
  return array.map(removeIdFromObject)
}

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import Icons from respective packages
import {
  faHouse,
  faEllipsis,
  faChevronDown,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faCheck,
  faXmark as faXmarkSolid,
  faLock,
  faMicrophone,
  faCamera,
  faEllipsisVertical as faEllipsisVerticalSolid,
  faPersonRunning,
} from '@fortawesome/free-solid-svg-icons'
import {
  faXmark as faXmarkRegular,
  faScaleUnbalanced,
  faList,
  faTrashCan,
  faCircleCheck,
  faCircleXmark,
  faClone,
  faPen,
  faCircleExclamation,
  faCircleEllipsis,
  faRotateRight,
  faMinus,
  faMagnifyingGlass,
  faLayerGroup,
  faPercent,
  faPlus,
  faPlayCircle,
  faCircleInfo,
  faHand,
  faCodeBranch,
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFilePdf,
  faFileZip,
  faFileWord,
  faFileSpreadsheet,
  faFilePowerpoint,
  faFileLines,
  faFile,
  faMessage,
  faPaperclip,
  faPaperPlaneTop,
  faBoxArchive,
  faArrowLeft as faArrowLeftRegular,
  faArrowRight as faArrowRightRegular,
  faClockThree,
  faCircleQuestion,
  faPenToSquare,
  faComments,
  faFiles,
  faBookmark,
  faEllipsisVertical,
  faPersonWalkingArrowRight,
  faPaintRoller,
  faEye,
  faEyeSlash,
  faGear,
  faArrowRightFromBracket,
  faArrowsRepeat,
  faGearComplexCode,
  faEnvelope,
  faCommentDots,
  faLink,
  faHandshakeAngle as faHandshakeAngleRegular,
  faCheck as faCheckRegular,
  faFileExport,
  faWifiSlash,
  faFloppyDisk,
  faPenLine,
  faDownload,
  faIslandTropical as faIslandTropicalRegular,
  faArrowTurnDownLeft,
  faUser as faUserRegular,
  faUserMinus,
  faClipboardListCheck,
  faArrowUpRightFromSquare,
  faCode,
  faWindowMaximize,
  faCirclePlay,
  faSubtitles,
  faUpload,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faGripDotsVertical,
  faChevronLeft,
  faChevronRight,
  faMessages,
  faArrowLeft,
  faArrowRight,
  faInbox,
  faReply,
  faUser,
  faUsers,
  faTriangle,
  faScreencast,
  faPhoneHangup,
  faIslandTropical,
  faHandshakeAngle,
  faFile as faFileSolid,
  faSquareCode,
  faTags,
  faVial,
  faFlaskVial,
  faTable,
  faCaretRight,
  faBookSparkles,
  faCirclesOverlap,
  faPen as faPenSolid,
  faClipboardListCheck as faClipboardListCheckSolid,
} from '@fortawesome/pro-solid-svg-icons'

// Add Icons to library for access in other components
library.add(
  faHouse,
  faXmarkSolid,
  faXmarkRegular,
  faScaleUnbalanced,
  faList,
  faPen,
  faEllipsis,
  faPlus,
  faTrashCan,
  faGripDotsVertical,
  faCircleCheck,
  faCircleXmark,
  faClone,
  faChevronDown,
  faCircleExclamation,
  faRotateRight,
  faMinus,
  faCircleEllipsis,
  faCheck,
  faMagnifyingGlass,
  faLayerGroup,
  faPercent,
  faPlayCircle,
  faCircleInfo,
  faChevronLeft,
  faChevronRight,
  faLock,
  faMicrophone,
  faCamera,
  faHand,
  faScreencast,
  faPhoneHangup,
  faEllipsisVertical,
  faCodeBranch,
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFilePdf,
  faFileZip,
  faFileWord,
  faFileSpreadsheet,
  faFilePowerpoint,
  faFileLines,
  faFile,
  faChevronLeft,
  faChevronRight,
  faLock,
  faMessage,
  faMessages,
  faArrowLeft,
  faPaperclip,
  faPaperPlaneTop,
  faInbox,
  faBoxArchive,
  faReply,
  faUser,
  faUsers,
  faArrowLeftRegular,
  faArrowRightRegular,
  faClockThree,
  faCircleQuestion,
  faPenToSquare,
  faComments,
  faFiles,
  faBookmark,
  faEllipsisVertical,
  faTriangle,
  faPersonWalkingArrowRight,
  faChevronLeftSolid,
  faChevronRightSolid,
  faEllipsisVerticalSolid,
  faPaintRoller,
  faIslandTropical,
  faHandshakeAngle,
  faFileSolid,
  faSquareCode,
  faEye,
  faGear,
  faArrowRightFromBracket,
  faPersonRunning,
  faTags,
  faVial,
  faFlaskVial,
  faTable,
  faCaretRight,
  faArrowsRepeat,
  faGearComplexCode,
  faBookSparkles,
  faEnvelope,
  faCommentDots,
  faLink,
  faEyeSlash,
  faHandshakeAngleRegular,
  faCheckRegular,
  faFileExport,
  faWifiSlash,
  faCirclesOverlap,
  faFloppyDisk,
  faPenLine,
  faDownload,
  faIslandTropicalRegular,
  faArrowTurnDownLeft,
  faUserRegular,
  faUserMinus,
  faClipboardListCheck,
  faPenSolid,
  faClipboardListCheckSolid,
  faArrowUpRightFromSquare,
  faCode,
  faWindowMaximize,
  faCirclePlay,
  faSubtitles,
  faUpload,
  faArrowRight,
)

// setup nuxt plugin
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('FontAwesomeIcon', FontAwesomeIcon)
})

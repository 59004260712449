import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import { defu } from 'defu'
import { useWorkspaceStore } from '~/stores/workspace'
import { getTestDefault } from '~/static/data/tests/testDefaults'
import { type Test, type TestGradingCategory } from '~/types/Test'
import { StateError } from '~/errors/StateError'
import { NotFoundError } from '~/errors/NotFoundError'
import { AuthoringStatus } from '~/types/AuthoringStatus'
import { duplicateDeepWithIds } from '~/utils/duplicate'
import { removeDatabaseMetaDeep } from '~/utils/removeKeys'
import { useUserStore } from '~/stores/user'

export const useTestStore = defineStore('test', () => {
  const TestService = testService()
  const { addToast, removeToastsByType } = useToastNotifications()

  // OTHER STORES ========================================
  const workspaceStore = useWorkspaceStore()
  const userStore = useUserStore()

  // STATE ==============================================
  const tests = ref<Test[]>([])

  const testCount = computed<number>(() => tests.value.length)

  const publishedTestCount = computed(() => {
    return tests.value.filter((test) => test.isPublished).length
  })

  const unpublishedTestCount = computed(() => {
    return tests.value.filter(
      (test) =>
        !test.isPublished && test.authoringStatus !== AuthoringStatus.Draft,
    ).length
  })

  const draftTestCount = computed(() => {
    return tests.value.filter(
      (test) => test.authoringStatus === AuthoringStatus.Draft,
    ).length
  })

  const latestUpdatedAtTimestamp = useLatestUpdatedAtTimestamp(tests)

  // CRUD ========================================
  function getTestById(testId: string) {
    const response = tests.value.find((test) => test.id === testId)

    if (!response) {
      Sentry.captureException(
        new NotFoundError(`Test with ID ${testId} not found`),
      )
    }

    return response
  }

  async function createTest(test: Partial<Test>) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError('Error creating test. No workspace defined.')

      Sentry.captureException(error)

      return { data: null, error }
    }

    if (!userStore.user?.id) {
      const error = new StateError(
        'Error creating test. No user and/or user id defined.',
      )

      Sentry.captureException(error)

      return { data: null, error }
    }

    const { data, error } = await TestService.postTest(
      workspaceStore.currentWorkspaceId,
      defu(
        {
          primaryWorkspaceId: workspaceStore.currentWorkspaceId,
          createdBy: userStore.user.id,
        },
        test,
        getTestDefault(test.format),
      ),
    )

    if (error !== null) {
      return { data, error }
    }

    tests.value.push(data)

    return { data, error }
  }

  async function updateTestById(testId: string, test: Partial<Test>) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        `The test with ID ${testId} could not be updated because no workspace was defined.`,
      )

      Sentry.captureException(error)

      return { data: null, error }
    }

    const { data, error } = await TestService.updateTestById(
      workspaceStore.currentWorkspaceId,
      testId,
      test,
    )

    if (error != null) {
      return { data, error }
    }

    const index = tests.value.findIndex((test) => test.id === testId)
    tests.value[index] = { ...tests.value[index], ...data }

    return { data, error }
  }

  async function deleteTestById(testId: string) {
    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        `The test with ID ${testId} could not be deleted because no workspace was defined.`,
      )

      Sentry.captureException(error)

      return { error }
    }

    const { error } = await TestService.deleteTestById(
      workspaceStore.currentWorkspaceId,
      testId,
    )

    if (error !== null) {
      return { error }
    }

    tests.value = tests.value.filter((test) => test.id !== testId)

    return { error }
  }

  function getTestsBySimulationId(simulationId: string) {
    return tests.value.filter((test) =>
      test.questions.some((q) => q.simulationId === simulationId),
    )
  }

  function getGradingCategoryById(
    gradingCategoryId: string,
  ): TestGradingCategory | undefined {
    const test = tests.value.find((test) =>
      test.rubric.some((gc) => gc.id === gradingCategoryId),
    )

    if (!test) return undefined

    return test.rubric.find((gc) => gc.id === gradingCategoryId)
  }

  async function duplicateTestById(testId: string) {
    const test = getTestById(testId)

    if (!test) {
      const error = new NotFoundError(
        `Error duplicating test. Test to duplicate with id ${testId} not found.`,
      )

      Sentry.captureException(error)

      addToast({
        type: 'error',
        heading: 'Error duplicating test',
        message:
          'The test could not be duplicated because the test was not found. Please try again.',
        life: 5000,
      })

      return { data: null, error }
    }

    addToast({
      type: 'spinner',
      heading: 'Duplicating test',
      message: `Duplicating test '${test.title ?? '[Untitled Test]'}'...`,
    })

    let newTest = duplicateDeepWithIds(test)

    newTest = removeDatabaseMetaDeep(newTest, [
      'createdAt',
      'updatedAt',
      'deletedAt',
      'lastEditBy',
    ])

    newTest.title = newTest.title
      ? `${newTest.title} (Copy)`
      : '[Untitled Copy]'

    if (!workspaceStore.currentWorkspaceId) {
      const error = new StateError(
        'Error duplicating test. No workspace defined.',
      )

      Sentry.captureException(error)

      removeToastsByType('spinner')
      addToast({
        type: 'error',
        heading: 'Error duplicating test',
        message:
          'The test could not be duplicated because the workspace was not found. Please try again.',
        life: 5000,
      })

      return { data: null, error }
    }

    if (!userStore.user?.id) {
      const error = new StateError(
        'Error duplicating test. No user and/or user id defined.',
      )

      Sentry.captureException(error)

      removeToastsByType('spinner')
      addToast({
        type: 'error',
        heading: 'Error duplicating test',
        message:
          'The test could not be duplicated because the user was not found. Please try again.',
        life: 5000,
      })

      return { data: null, error }
    }

    const { data, error } = await TestService.postTest(
      workspaceStore.currentWorkspaceId,
      defu(
        {
          primaryWorkspaceId: workspaceStore.currentWorkspaceId,
          createdBy: userStore.user.id,
        },
        newTest,
        getTestDefault(newTest.format),
      ),
    )

    if (error !== null) {
      removeToastsByType('spinner')
      addToast({
        type: 'error',
        heading: 'Error duplicating test',
        message:
          'The test could not be duplicated because there was a server error. Please try again.',
        life: 5000,
      })
      return { data, error }
    }

    tests.value.push(data)

    removeToastsByType('spinner')

    return { data, error }
  }

  // API CALLS ========================================
  async function fetchTestsFromServer(workspaceId: string) {
    if (!workspaceId) return

    // Fetch tests from the API
    const { data, error } = await TestService.getWorkspaceTests(workspaceId)

    if (error !== null) {
      addToast({
        type: 'error',
        heading: 'Failed to fetch tests',
        message: 'There was an issue fetching tests.',
        life: 5000,
      })
      return
    }

    tests.value = data.local
  }

  async function fetchTestUpdatesFromServer(workspaceId: string) {
    if (!workspaceId) return

    if (!latestUpdatedAtTimestamp.value) {
      Sentry.captureException(
        new StateError(
          'No latest updated at timestamp found. This should not happen.',
        ),
      )
      return
    }

    const { data, error } = await TestService.getWorkspaceTestUpdates(
      workspaceId,
      latestUpdatedAtTimestamp.value,
    )

    if (error != null) {
      addToast({
        type: 'error',
        heading: 'Error refreshing tests',
        message:
          'Tests could not be refreshed. If the problem persists, please try reloading the page.',
        life: 5000,
      })
      return
    }

    tests.value = upsertArrayById(tests.value, data.local)
  }

  function reset() {
    tests.value = []
  }

  const {
    $isInitialised,
    $isInitialising,
    $isUpdating,
    $initialise,
    $ensureInitialised,
    $reset,
    $refresh,
  } = useStoreManager(
    computed(() => workspaceStore.currentWorkspaceId),

    async () => {
      await workspaceStore.$ensureInitialised()

      reset()

      if (!workspaceStore.currentWorkspaceId) return false

      await fetchTestsFromServer(workspaceStore.currentWorkspaceId)

      return true
    },

    reset,

    async () => {
      if (!workspaceStore.currentWorkspaceId) return

      await fetchTestUpdatesFromServer(workspaceStore.currentWorkspaceId)
    },
  )

  return {
    $reset,
    $refresh,
    $ensureInitialised,
    $initialise,
    $isInitialising,
    $isInitialised,
    $isUpdating,
    tests,
    testCount,
    publishedTestCount,
    unpublishedTestCount,
    draftTestCount,
    getTestById,
    updateTestById,
    createTest,
    deleteTestById,
    getTestsBySimulationId,
    getGradingCategoryById,
    duplicateTestById,
  }
})

function getIdsDeep(object: any, ids: string[]) {
  if (typeof object === 'object' && object !== null) {
    Object.keys(object).forEach((key) => {
      if (key === 'id' && typeof object[key] === 'string') {
        ids.push(object[key])
      } else {
        getIdsDeep(object[key], ids)
      }
    })
  }

  if (Array.isArray(object)) {
    object.forEach((item) => {
      getIdsDeep(item, ids)
    })
  }

  return ids
}

interface OldNewIdRecord {
  oldId: string
  newId: string
}

export function duplicateDeepWithIds<T>(object: T): T {
  // Get all the ids in the object and create a new id for each
  const oldNewIds: OldNewIdRecord[] = getIdsDeep(object, []).map((id) => ({
    oldId: id,
    newId: getNewId(),
  }))

  const clonedObject = getDeepClone(object)

  let stringifiedOutput = JSON.stringify(clonedObject)

  // Replace every occurrence of the old id (most of which will be foreign keys) with the new id
  oldNewIds.forEach(({ oldId, newId }) => {
    stringifiedOutput = stringifiedOutput.replaceAll(oldId, newId)
  })

  return JSON.parse(stringifiedOutput)
}

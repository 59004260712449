<template>
  <NuxtErrorBoundary @error="(e: any) => handleUncaughtError(e)">
    <div>
      <NuxtLoadingIndicator
        color="repeating-linear-gradient(to right,#4f46e5 20%,#4f46e5 100%)"
        :height="2"
      />
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
      <!-- TOAST -->
      <AppToastNotificationDefault />
      <AppToastNotificationError />
      <AppToastNotificationInteractionEnd />
      <AppToastNotificationOffline />
      <AppToastNotificationFileSubmissionUpload />
      <AppToastNotificationFileSubmissionProcessing />
      <AppToastNotificationSpinner />
      <!-- DIALOG -->
      <DialogHandler />
    </div>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import * as Sentry from '@sentry/vue'

function handleUncaughtError(e: any) {
  Sentry.captureException(e)
  console.error(e)
  showError({
    statusCode: e.statusCode || 500,
    statusMessage:
      e.statusMessage || e.message || 'An unexpected error occurred',
    message: e.message || e.statusMessage || 'An unexpected error occurred',
  })
}

const { addToast, removeToastsByType } = useToastNotifications()
const isOnline = useOnlineStatus()

watch(isOnline, (online) => {
  if (online) {
    removeToastsByType('offline')
  } else {
    addToast({
      type: 'offline',
      heading: 'You are offline',
      message: '',
    })
  }
})
</script>

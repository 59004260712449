import { httpService } from '~/services/httpService'
import type { ShareableEntityList } from '~/types/ShareableEntityList'
import type { Test } from '~/types/Test'
import type {
  PopulatedTestAttempt,
  QuestionAttempt,
  TestAttempt,
  TestAttemptGrade,
  TestAttemptWithGradeAndCandidate,
} from '~/types/TestAttempt'
import type { UploadedFile } from '~/types/UploadedFile'
import { fileUploadService } from '~/services/fileUploadService'

export function testService() {
  const config = useRuntimeConfig()
  const serverUrl = config.public.serverUrl

  const FileUploadService = fileUploadService()

  const { getResource, deleteResource, postResource, patchResource } =
    httpService<Test>(serverUrl)

  async function getTests(queryParameters: Record<string, string> = {}) {
    const response = await getResource<Test[]>('/tests', {
      query: queryParameters,
    })
    return response
  }

  async function getTestById(testId: string) {
    const response = await getResource(`tests/${testId}`, {
      useFallbackAuth: true,
    })
    return response
  }

  async function getWorkspaceTests(workspaceId: string) {
    const response = await getResource<ShareableEntityList<Test>>(
      `/workspaces/${workspaceId}/tests`,
    )
    return response
  }

  async function postTest(workspaceId: string, body: Partial<Test>) {
    const response = await postResource(
      `/workspaces/${workspaceId}/tests`,
      body,
    )
    return response
  }

  async function updateTestById(
    workspaceId: string,
    testId: string,
    body: Partial<Test>,
  ) {
    const response = await patchResource(
      `/workspaces/${workspaceId}/tests/${testId}`,
      body,
    )
    return response
  }

  async function deleteTestById(workspaceId: string, testId: string) {
    const response = await deleteResource(
      `/workspaces/${workspaceId}/tests/${testId}`,
    )
    return response
  }

  async function getWorkspaceTestUpdates(
    workspaceId: string,
    updatedAtGt: string,
  ) {
    const response = await getResource<ShareableEntityList<Test>>(
      `/workspaces/${workspaceId}/tests`,
      {
        query: {
          updatedAtGt,
        },
      },
    )
    return response
  }

  async function createTestAttempt(testId: string, body: Partial<TestAttempt>) {
    const response = await postResource(`/tests/${testId}/attempts`, body)
    return response
  }

  async function updateTestAttempt(
    testId: string,
    testAttemptId: string,
    body: Partial<TestAttempt>,
  ) {
    const response = await patchResource(
      `/tests/${testId}/attempts/${testAttemptId}`,
      body,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function deleteTestAttempt(testId: string, testAttemptId: string) {
    const response = await deleteResource(
      `/tests/${testId}/attempts/${testAttemptId}`,
    )
    return response
  }

  async function createQuestionAttempt(
    questionId: string,
    body: Partial<QuestionAttempt>,
  ) {
    const response = await postResource(
      `/questions/${questionId}/attempts`,
      body,
    )
    return response
  }

  async function updateQuestionAttempt(
    questionId: string,
    questionAttemptId: string,
    body: Partial<QuestionAttempt>,
  ) {
    const response = await patchResource(
      `/questions/${questionId}/attempts/${questionAttemptId}`,
      body,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function deleteQuestionAttempt(
    questionId: string,
    questionAttemptId: string,
  ) {
    const response = await deleteResource(
      `/questions/${questionId}/attempts/${questionAttemptId}`,
    )
    return response
  }

  async function initialiseTestAttempt(testId: string, candidateId: string) {
    const response = await postResource<
      {
        testAttempt: TestAttempt
        questionAttempts: QuestionAttempt[]
      },
      { candidateId: string }
    >(
      `/tests/${testId}/attempts/initialize`,
      {
        candidateId,
      },
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function getTestAttemptsByCandidateId(
    testId: string,
    candidateId: string,
  ) {
    const response = await getResource<TestAttempt[]>(
      `/tests/${testId}/attempts/candidate/${candidateId}`,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function getTestAttemptsByTestId(testId: string) {
    const response = await getResource<TestAttemptWithGradeAndCandidate[]>(
      `/tests/${testId}/attempts`,
    )
    return response
  }

  async function getTestAttemptById(testId: string, testAttemptId: string) {
    const response = await getResource<PopulatedTestAttempt>(
      `/tests/${testId}/attempts/${testAttemptId}`,
    )
    return response
  }

  async function requestTestAttemptGrade(
    testId: string,
    testAttemptId: string,
  ) {
    const response = await postResource<string, undefined>(
      `/tests/${testId}/attempts/${testAttemptId}/request/grade`,
      undefined,
    )
    return response
  }

  async function requestTestAttemptReGrade(
    testId: string,
    testAttemptId: string,
  ) {
    const response = await postResource<string, undefined>(
      `/tests/${testId}/attempts/${testAttemptId}/request/re-grade`,
      undefined,
    )
    return response
  }

  async function requestTestAttemptGradeAll(
    testId: string,
    regrade: boolean = false,
  ) {
    const regradeQuery = regrade ? 'true' : 'false'
    const response = await postResource<string, undefined>(
      `/tests/${testId}/attempts/grade?regrade=${regradeQuery}`,
      undefined,
    )
    return response
  }

  // TEST ATTEMPT SESSIONS ========================================================

  async function getTestAttemptSession(
    testId: string,
    candidateId: string,
    assessmentIds: string[] = [],
  ) {
    const response = await postResource<
      {
        sessionId: string
        testAttempt: TestAttempt
        questionAttempts: QuestionAttempt[]
      },
      { candidateId: string }
    >(
      `/tests/${testId}/attempts/sessions`,
      {
        candidateId,
      },
      {
        useFallbackAuth: true,
        params: {
          a: assessmentIds,
        },
      },
    )
    return response
  }

  async function startTestAttemptSession(testId: string, sessionId: string) {
    const response = await postResource<undefined, undefined>(
      `/tests/${testId}/attempts/sessions/${sessionId}/start`,
      undefined,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function completeTestAttemptSession(testId: string, sessionId: string) {
    const response = await postResource<undefined, undefined>(
      `/tests/${testId}/attempts/sessions/${sessionId}/complete`,
      undefined,
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function reuseTestAttemptForSession(
    testId: string,
    sessionId: string,
    testAttemptId: string,
  ) {
    const response = await postResource<undefined, { testAttemptId: string }>(
      `/tests/${testId}/attempts/sessions/${sessionId}/reuse-test-attempt`,
      {
        testAttemptId,
      },
      {
        useFallbackAuth: true,
      },
    )
    return response
  }

  async function uploadGradingBlockContextFiles(
    testId: string,
    gradingBlockId: string,
    files: File[],
  ) {
    const response = await FileUploadService.uploadFilesToEndpoint<
      {
        testId: string
        gradingBlockId: string
        file: UploadedFile
      }[]
    >(`/tests/${testId}/grading-blocks/${gradingBlockId}/context-files`, files)
    return response
  }

  async function deleteGradingBlockContextFiles(
    testId: string,
    gradingBlockId: string,
  ) {
    const response = await deleteResource(
      `/tests/${testId}/grading-blocks/${gradingBlockId}/context-files`,
    )
    return response
  }

  async function uploadTestContextFiles(testId: string, files: File[]) {
    const response = await FileUploadService.uploadFilesToEndpoint<
      {
        testId: string
        file: UploadedFile
      }[]
    >(`/tests/${testId}/context-files`, files)
    return response
  }

  async function deleteTestContextFiles(testId: string) {
    const response = await deleteResource(`/tests/${testId}/context-files`)
    return response
  }

  async function uploadFileSubmissions(
    testId: string,
    files: File[] | FileList,
    organization: string,
  ) {
    const response = await FileUploadService.uploadFilesToEndpoint(
      `/tests/${testId}/attempts/file-submissions`,
      files,
      {
        organization,
      },
    )

    return response
  }

  async function getTestAttemptGrade(testAttemptGradeId: string) {
    const response = await getResource<TestAttemptGrade>(
      `/test-attempt-grades/${testAttemptGradeId}`,
    )
    return response
  }

  async function getResultsCsvLink(testId: string, withRemarks: boolean) {
    const response = await getResource<string>(
      `/tests/${testId}/attempts/grades/results/csv?withRemarks=${withRemarks}`,
    )
    return response
  }

  async function getIndividualResultsCsvLink(
    testId: string,
    testAttemptGradeId: string,
    withRemarks: boolean,
  ) {
    const response = await getResource<string>(
      `/tests/${testId}/attempts/grades/${testAttemptGradeId}/results/csv?withRemarks=${withRemarks}`,
    )
    return response
  }

  return {
    getTests,
    getTestById,
    getWorkspaceTests,
    postTest,
    updateTestById,
    deleteTestById,
    getWorkspaceTestUpdates,
    createTestAttempt,
    updateTestAttempt,
    deleteTestAttempt,
    createQuestionAttempt,
    updateQuestionAttempt,
    deleteQuestionAttempt,
    initialiseTestAttempt,
    getTestAttemptsByTestId,
    getTestAttemptById,
    requestTestAttemptGrade,
    requestTestAttemptReGrade,
    requestTestAttemptGradeAll,
    getTestAttemptSession,
    getTestAttemptsByCandidateId,
    startTestAttemptSession,
    completeTestAttemptSession,
    reuseTestAttemptForSession,
    uploadGradingBlockContextFiles,
    deleteGradingBlockContextFiles,
    uploadFileSubmissions,
    uploadTestContextFiles,
    deleteTestContextFiles,
    getTestAttemptGrade,
    getResultsCsvLink,
    getIndividualResultsCsvLink,
  }
}
